<template>
  <section style="overflow-x: auto">
    <div>
      <div v-show="false">
        <b-form-input
          id="range-1"
          v-model="rate"
          type="range"
          :min="0.5"
          :max="2.0"
          :step="0.001"
          style="max-width: 200px"
          class="ml-1"
        ></b-form-input>
        <span>x {{ rate }}</span>
      </div>

      <!--Date,Time表示-->
      <GanttTime
        class="timeSt"
        :rate="rate"
        :dates="getHyojiDates"
        :hyojiStart="startDate"
        :hyojiEnd="endDate"
      />

      <div v-for="(vehicle, index) in ganttChartVehicleList" :key="index">
        <div class="chartBarSt">
          <div class="chartListSt">
            <GanttChartRow
              :charts="vehicle.ganttChartGanttDtos"
              :hyojiStart="startDate"
              :hyojiEnd="endDate"
              :dates="getHyojiDates"
              :rate="rate"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    /**
     * GanttChart情報.
     * @type {Array}
     * @requires
     */
    ganttChartVehicleList: {
      type: Array,
      default: () => null,
    },
    startDate: {
      type: String,
      default: '',
    },
    endDate: {
      type: String,
      default: '',
    },
  },
  components: {
    GanttChartRow: () => import('@/plan/manualPlanning/gantt/GanttChartRow'),
    GanttTime: () => import('@/plan/manualPlanning/gantt/GanttTime'),
  },
  name: 'VueCharts',
  data() {
    return {
      rate: 1,
    }
  },
  methods: {
    getOnlyDate(value) {
      let year = value.getFullYear()
      let monthIndex = value.getMonth()
      let date = value.getDate()
      var onlyDate = new Date(year, monthIndex, date)
      return onlyDate
    },

    convertDate(value) {
      var year = value.substring(0, 4)
      var monthIndex = Number(value.substring(4, 6)) - 1 // monthIndex: 0~11
      var day = value.substring(6, 8)
      var hour = value.substring(8, 10)
      var minute = value.substring(10, 12)
      var second = value.substring(12, 14)

      value = new Date(year, monthIndex, day, hour, minute, second)

      return value
    },
  },
  computed: {
    //表示期間
    getHyojiDates() {
      let start = this.convertDate(this.startDate)
      let end = this.convertDate(this.endDate)
      var date = Number(end.getDate() - start.getDate())
      return date
    },
  },
}
</script>
<style scoped>
.timest {
  text-align: left;
  margin-left: 0px;
}
.timest span {
  margin-right: 80px;
}
.datest {
  text-align: left;
  margin-left: 145px;
}
.datest span {
  margin-right: 160px;
}
.chartListSt {
  width: 1500px;
}
.chartBtn {
  width: 150px;
  height: 70px;
  background-color: rgb(255, 130, 130);
  color: black;
  text-align: left;
  margin-right: 15px;
  margin-left: 20px;
  align-items: center;
  float: left;
}
.chartBarSt {
  height: 92px;
  width: auto;
  white-space: nowrap;
  margin-left: 17px;
}
.chartBarSt svg {
  width: 3000px;
  height: 92px;
  border-left: 2px black solid;
}
.truckSt {
  width: 160px;
  float: left;
}
</style>
